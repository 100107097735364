<template>
  <div class="row">
    <el-dialog
      :title="$t('usersTables.title')"
      :visible.sync="visible"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item :label="$t('usersTables.userID')">
          <el-input
            v-model="form.user_id"
            :disabled="dialogStatus === 'add' ? false : true"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('usersTables.name')" prop="name">
          <el-input
            v-model="form.name"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>

        <el-form-item :label="$t('usersTables.group')">
          <el-checkbox
            :indeterminate="isGroupIndeterminate"
            v-model="groupCheckAll"
            @change="handleGroupCheckAllChange"
            :disabled="dialogStatus === 'info' ? true : false"
            >{{ $t("usersTables.selectAll") }}</el-checkbox
          >
          <div style="margin: 15px 0"></div>
          <el-checkbox-group
            v-model="form.group"
            @change="handleCheckedGroupChange"
            :disabled="dialogStatus === 'info' ? true : false"
          >
            <el-checkbox v-for="gp in group" :label="gp" :key="gp">{{
              gp
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item :label="$t('usersTables.createDevice')">
          <el-checkbox
            :indeterminate="isDeviceIndeterminate"
            v-model="deviceCheckAll"
            @change="handleDeviceCheckAllChange"
            :disabled="dialogStatus === 'info' ? true : false"
            >{{ $t("usersTables.selectAll") }}</el-checkbox
          >
          <div style="margin: 15px 0"></div>
          <el-checkbox-group
            v-model="form.devices"
            @change="handleCheckedDeviceChange"
            :disabled="dialogStatus === 'info' ? true : false"
          >
            <el-checkbox
              v-for="device in devices"
              :label="device.sn"
              :key="device.sn"
              >{{ device.sn }}({{ device.name }})</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <el-form-item :label="$t('usersTables.phone')" prop="phone">
          <el-input
            v-model="form.phone"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('usersTables.cardID')" prop="ic">
          <el-input
            v-model="form.ic"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="用戶類型" prop="user_type">
          <el-select
            v-model="form.user_type"
            placeholder="請選擇用戶類型"
            :disabled="dialogStatus === 'info' ? true : false"
          >
            <el-option
              v-for="option in userTypeOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('usersTables.password')" prop="password">
          <el-input
            v-model="form.password"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('usersTables.personnelValidPeriod')"
          prop="id_valid"
        >
          <el-date-picker
            v-model="form.id_valid"
            type="date"
            placeholder="選擇日期"
            :picker-options="pickerOptions"
            :disabled="dialogStatus === 'info' ? true : false"
          >
          </el-date-picker>
        </el-form-item>

        <template v-if="showExpiryFields">
          <el-form-item
            label="體檢到期日"
            prop="medical_expiry"
          >
            <el-date-picker
              v-model="form.medical_expiry"
              type="date"
              placeholder="選擇日期"
              :picker-options="pickerOptions"
              :disabled="dialogStatus === 'info' ? true : false"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item
            label="教育訓練到期日"
            prop="training_expiry"
          >
            <el-date-picker
              v-model="form.training_expiry"
              type="date"
              placeholder="選擇日期"
              :picker-options="pickerOptions"
              :disabled="dialogStatus === 'info' ? true : false"
            >
            </el-date-picker>
          </el-form-item>
        </template>

        <el-form-item :label="$t('usersTables.passingTime')"
          ><el-button @click="addCycle" v-if="dialogStatus !== 'info'">{{
            $t("usersTables.passingTime")
          }}</el-button></el-form-item
        >
        <el-form-item
          v-for="(cycle, index) in form.valid_cycle"
          :label="$t('usersTables.passingTime')"
          :key="index"
          :prop="'valid_cycle.' + index + '.start_time'"
          :rules="{
            required: true,
            message: $t('usersTables.passingTimeNotEmpty'),
            trigger: 'blur',
          }"
        >
          <el-time-select
            :placeholder="$t('usersTables.startTime')"
            v-model="cycle.start_time"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
            }"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-time-select>
          -
          <el-time-select
            :placeholder="$t('usersTables.endTime')"
            v-model="cycle.end_time"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
            }"
            :disabled="dialogStatus === 'info' ? true : false"
          ></el-time-select>
          <el-button
            @click.prevent="removeCycle(cycle)"
            v-if="dialogStatus !== 'info'"
            >{{ $t("usersTables.delete") }}</el-button
          >
        </el-form-item>

        <el-form-item label="職安卡" v-if="showOSCCheck"
          ><el-button @click="oscCheck" v-if="dialogStatus !== 'info'"
            >職安卡確認</el-button
          ></el-form-item
        >

        <el-form-item :label="$t('usersTables.photo')" prop="face_image">
          <el-upload
            action=""
            accept=".jpg, .jpeg"
            :show-file-list="false"
            :on-change="imageChange"
            :auto-upload="false"
            :disabled="dialogStatus === 'info' ? true : false"
          >
            <img
              v-if="form.face_image"
              :src="form.face_image"
              class="face-image"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item
          :label="$t('usersTables.oshcard')"
          prop="oshcard_image"
          v-if="showOSCCheck"
        >
          <el-upload
            action=""
            accept=".jpg, .jpeg"
            :show-file-list="false"
            :on-change="oshcardChange"
            :auto-upload="false"
            :disabled="dialogStatus === 'info' ? true : false"
          >
            <img v-if="form.oshcard" :src="form.oshcard" class="face-image" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item
          :label="
            form.line_notify_url_status === 'bind'
              ? 'QR Code 綁定'
              : 'QR Code 綁定解除'
          "
          :hidden="
            dialogStatus === 'info' && form.line_notify_url !== ''
              ? false
              : true
          "
        >
          <template>
            <qrcode-vue :value="form.line_notify_url" size="150" level="M" />
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="handleClose">{{
          $t("usersTables.no")
        }}</el-button>
        <el-button type="primary" @click="handleSubmit('form')">{{
          $t("usersTables.yes")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import {
  Dialog,
  Button,
  Form,
  FormItem,
  Input,
  Checkbox,
  CheckboxGroup,
  Upload,
  TimeSelect,
  DatePicker,
  Col,
  Select,
  Option,
} from "element-ui";
import { Card } from "src/components/UIComponents";
import axios from "axios";
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Upload);
Vue.use(TimeSelect);
Vue.use(DatePicker);
Vue.use(Col);
Vue.use(Select);
Vue.use(Option);

export default {
  components: {
    Card,
    QrcodeVue,
  },
  name: "user-dialog",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    currentData: {
      type: Object,
      require: true,
      default: {},
    },
    dialogStatus: {
      type: String,
      require: true,
      defalut: "info",
    },
  },
  computed: {
    showOSCCheck() {
      console.log(process.env.SHOW_OSCCHECK);
      return process.env.SHOW_OSCCHECK === "true";
    },
    showExpiryFields() {
      console.log(process.env.SHOW_EXPIRY_FIELDS);
      return process.env.SHOW_EXPIRY_FIELDS === "true";
    },
  },
  data() {
    return {
      form: {
        user_id: "",
        name: "",
        group: [],
        devices: [],
        phone: "",
        ic: "",
        password: "",
        id_valid: "",
        medical_expiry: "",
        training_expiry: "",
        face_image: "",
        oshcard: "",
        valid_cycle: [],
        user_type: 0,
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("usersTables.enterName"),
            trigger: "blur",
          },
        ],
        face_image: [
          {
            required: true,
            message: this.$t("usersTables.uploadPhoto"),
            trigger: "blur",
          },
        ],
        oshcard_image: [
          {
            required: false,
            message: this.$t("usersTables.uploadOshcard"),
            trigger: "blur",
          },
        ],
      },
      devices: [],
      group: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [
          {
            text: this.$t("usersTables.today"),
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: this.$t("usersTables.tomorrow"),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: this.$t("usersTables.nextWeek"),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      deviceCheckAll: false,
      groupCheckAll: false,
      isDeviceIndeterminate: false,
      isGroupIndeterminate: false,
      userTypeOptions: [
        { value: 0, label: '一般用戶' },
        { value: 10, label: '黑名單' },
        { value: 23, label: '訪客' }
      ],
    };
  },
  created() {
    this.form = { ...this.currentData };
    this.getDevices();
    this.getGroup();
    console.log(this.form);
  },
  methods: {
    getDevices() {
      axios.get(`/face/device`).then((res) => {
        const devicesSn = res.data.rows.map((item, index, array) => {
          return {
            sn: item.sn,
            name: item.name,
          };
          // return item.sn;
        });
        if (this.dialogStatus === "add") {
          this.deviceCheckAll = true;
          this.form.devices = res.data.rows.map((item, index, array) => {
            return item.sn;
          });
        }
        this.devices = devicesSn;
      });
    },
    getGroup() {
      axios.get(`/face/group`).then((res) => {
        console.log(res.data);
        const group = res.data.rows.map((item, index, array) => {
          return item.name;
        });
        this.group = group;
        if (this.form.group.length) {
          this.group = [...new Set([...this.group, ...this.form.group])];
        }
        console.log(this.group);
      });
    },
    handleDeviceCheckAllChange(val) {
      const allDevices = this.devices.map((item, index, array) => {
        return item.sn;
      });
      this.form.devices = val ? allDevices : [];
      this.isDeviceIndeterminate = false;
    },
    handleCheckedDeviceChange(value) {
      let checkedCount = value.length;
      this.deviceCheckAll = checkedCount === this.devices.length;
      this.isDeviceIndeterminate =
        checkedCount > 0 && checkedCount < this.devices.length;
    },

    handleGroupCheckAllChange(val) {
      this.form.group = val ? this.group : [];
      this.isGroupIndeterminate = false;
    },
    handleCheckedGroupChange(value) {
      let checkedCount = value.length;
      this.groupCheckAll = checkedCount === this.group.length;
      this.isGroupIndeterminate =
        checkedCount > 0 && checkedCount < this.group.length;
    },

    removeCycle(item) {
      var index = this.form.valid_cycle.indexOf(item);
      if (index !== -1) {
        this.form.valid_cycle.splice(index, 1);
      }
    },
    addCycle() {
      this.form.valid_cycle.push({
        start_time: "",
        end_time: "",
      });
    },

    oscCheck() {
      axios
        .post(`/osc/faceDetector/recognition`, {
          photo: this.form.face_image,
        })
        .then(
          (res) => {
            this.$notify({
              title: "職安卡確認成功",
              message: res.data.message,
              verticalAlign: "bottom",
              icon: "nc-icon nc-check-2",
              type: "success",
            });
            this.form.name = res.data.laborAccount.name;
            this.form.user_id = res.data.laborAccount.account;
          },
          (res) => {
            this.$notify({
              title: "職安卡確認失敗",
              message: res.response.data.message,
              verticalAlign: "bottom",
              icon: "nc-icon nc-alert-circle-i",
              type: "danger",
            });
          }
        );
    },

    imageChange(file) {
      this.getBase64(file.raw).then((res) => {
        this.form.face_image = res;
      });
    },
    oshcardChange(file) {
      this.getBase64(file.raw).then((res) => {
        this.form.oshcard = res;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let img = "";
        reader.readAsDataURL(file);
        reader.onload = () => {
          img = reader.result;
        };
        reader.onerror = (err) => {
          reject(err);
        };
        reader.onloadend = () => {
          resolve(img);
        };
      });
    },

    handleClose() {
      this.$emit("close-dialog", { visible: !this.visible });
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 處理日期欄位，確保是台灣時間的 00:00:00
          const dateFields = ['id_valid', 'medical_expiry', 'training_expiry'];
          dateFields.forEach(field => {
            if (this.form[field]) {
              const date = new Date(this.form[field]);
              // 設定為台灣時間的 00:00:00
              date.setHours(0, 0, 0, 0);
              this.form[field] = date.toISOString();
            }
          });

          if (this.dialogStatus === "add") {
            axios
              .post(`/face/user`, {
                sn: this.form.devices,
                data: this.form,
              })
              .then(
                (res) => {
                  this.$notify({
                    title: this.$t("usersTables.addUserSuccess"),
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                },
                (res) => {
                  this.$notify({
                    title: this.$t("usersTables.addUserFailure"),
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                }
              );
          }
          if (this.dialogStatus === "edit") {
            axios
              .put(`/face/user`, {
                sn: this.form.devices,
                data: this.form,
              })
              .then(
                (res) => {
                  this.$notify({
                    title: this.$t("usersTables.editUserSuccess"),
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                },
                (res) => {
                  this.$notify({
                    title: this.$t("usersTables.editUserFailure"),
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                }
              );
          }
          this.$emit("close-dialog", {
            visible: !this.visible,
            data: this.form,
          });
        }
      });
    },
    handleLineNotify(formName) {
      window.open(this.form.line_notify_url);
    },
  },
};
</script>

<style lang="scss" scoped></style>
