<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">{{ $t("usersTables.title") }}</h4>

      <card>
        <!-- <h4 slot="header" class="card-title"></h4> -->
        <el-row type="flex" :gutter="20">
          <el-col :span="19">
            <div class="grid-content bg-purple">
              <el-button
                style="margin-left: 10px"
                size="small"
                type="success"
                @click="handleAddUser()"
                >{{ $t("usersTables.handleAddUser") }}</el-button
              >
            </div></el-col
          >
          <el-col :span="1">
            <div class="grid-content bg-purple">
              <el-button
                style="margin-left: 10px"
                size="small"
                type="danger"
                @click="handleDeleteAllUser()"
                >{{ $t("usersTables.handleDeleteAllUser") }}</el-button
              >
            </div></el-col
          >
        </el-row>
      </card>

      <!-- end card -->
    </div>
    <div class="col-md-12">
      <h4 class="title">{{ $t("usersTables.handleDeleteAllUser") }}</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-header"></div>
      <div class="card-body row">
        <div class="col-sm-8">
          <el-select
            class="select-default"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-2">
          <div class="pull-right">
            <el-date-picker
              :localTime="true"
              v-model="updatedAt"
              type="daterange"
              range-separator="~"
              :start-placeholder="$t('usersTables.daterangeStart')"
              :end-placeholder="$t('usersTables.daterangeEnd')"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="Search"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            v-loading="loading"
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :formatter="column.formatter"
            >
            </el-table-column>
            <el-table-column prop="group" :label="$t('usersTables.group')">
              <template slot-scope="scope">
                <el-tag :key="gp" v-for="gp in scope.row.group" type="primary">
                  {{ gp }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="photo" :label="$t('usersTables.photo')">
              <template slot-scope="scope">
                <img :src="scope.row.face_image" />
              </template>
            </el-table-column>
            <el-table-column prop="devices" :label="$t('usersTables.device')">
              <template slot-scope="scope">
                <el-tag
                  :key="device"
                  v-for="device in scope.row.devices"
                  type="primary"
                  size="mini"
                >
                  {{ device }}
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column
              :min-width="140"
              fixed="right"
              class-name="td-actions"
              :label="$t('usersTables.feature')"
            >
              <template slot-scope="props">
                <p-button
                  type="info"
                  size="sm"
                  icon
                  :title="$t('usersTables.personnelInfo')"
                  @click="handleInfo(props.$index, props.row)"
                >
                  <i class="fa fa-user"></i>
                </p-button>
                <p-button
                  type="success"
                  size="sm"
                  icon
                  :title="$t('usersTables.editPersonnel')"
                  @click="handleEdit(props.$index, props.row)"
                >
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button
                  type="danger"
                  size="sm"
                  icon
                  :title="$t('usersTables.deletePersonnel')"
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            class="pull-right"
            v-model="pagination.currentPage"
            @change-page="handleCurrentChange"
            :per-page="pagination.perPage"
            @per-page="handleSizeChange"
            :total="pagination.total"
          >
          </p-pagination>
        </div>
      </div>
    </div>

    <div v-if="dialogVisible">
      <userDialog
        :visible="dialogVisible"
        @close-dialog="handleCloseDialog"
        :current-data="currentData"
        :dialog-status="dialogStatus"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert2";
import moment from "moment";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Tag,
  Loading,
  Row,
} from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
import userDialog from "./UserDialog.vue";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Loading);
Vue.use(Row);
export default {
  components: {
    PPagination,
    [Tag.name]: Tag,
    userDialog,
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 200],
        total: 0,
      },
      userTypeOptions: [
        { value: 0, label: '一般用戶' },
        { value: 10, label: '黑名單' },
        { value: 23, label: '訪客' }
      ],
      searchQuery: "",
      updatedAt: [],
      propsToSearch: ["user_name", "email", "age"],
      tableColumns: [
        {
          prop: "id",
          label: this.$t("usersTables.id"),
        },
        {
          prop: "user_id",
          label: this.$t("usersTables.userID"),
        },
        {
          prop: "name",
          label: this.$t("usersTables.name"),
        },
        {
          prop: "ic",
          label: this.$t("usersTables.cardID"),
        },
        {
          prop: "createdAt",
          label: this.$t("usersTables.createdAt"),
          formatter(row, column, cellValue, index) {
            return moment(cellValue).local().format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          prop: "updatedAt",
          label: this.$t("usersTables.updatedAt"),
          formatter(row, column, cellValue, index) {
            return moment(cellValue).local().format("YYYY-MM-DD HH:mm:ss");
          },
        },
      ],
      tableData: [],
      dialogVisible: false,
      currentData: {},
      dialogStatus: "info",
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    searchQuery: function () {
      this.getData();
    },
    updatedAt: function () {
      this.getData();
    },
  },
  methods: {
    getData() {
      const params = {};
      params.limit = this.pagination.perPage;
      params.index = this.pagination.currentPage;
      params.q = this.searchQuery ? this.searchQuery : undefined;
      if (this.updatedAt) {
        params.updatedAt =
          this.updatedAt.length !== 0 ? this.updatedAt : undefined;
      }

      console.log(params);

      this.loading = true;
      axios.get(`/face/user`, { params }).then((res) => {
        this.tableData = res.data.rows;
        this.pagination.total = res.data.count;
        this.loading = false;
      });
    },
    handleSizeChange(size) {
      this.pagination.perPage = size;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.getData();
    },
    handleAddUser() {
      this.currentData = {
        valid_cycle: [],
        devices: [],
        user_id: null,
        name: null,
        nickname: null,
        face_image: null,
        ic: null,
        password: null,
        user_type: 0,
        phone: null,
        id_valid: null,
        group: [],
        oshcard: null,
      };
      this.dialogVisible = true;
      this.dialogStatus = "add";
    },
    handleInfo(index, row) {
      this.currentData = row;
      this.dialogVisible = true;
      this.dialogStatus = "info";
    },
    handleEdit(index, row) {
      this.currentData = row;
      this.dialogVisible = true;
      this.dialogStatus = "edit";
      // alert(`Your want to edit ${row.name}`);
    },
    handleCloseDialog({ visible }) {
      // this.data.find({ user_id } => user_id === data.user_id)
      this.dialogVisible = visible;
      this.timeOutReload = setTimeout(() => {
        this.getData();
      }, 500);
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: this.$t("usersTables.confirmRemove"),
          showDenyButton: true,
          confirmButtonText: this.$t("usersTables.yes"),
          denyButtonText: this.$t("usersTables.no"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/face/user`, {
                data: {
                  sn: row.devices,
                  data: {
                    user_id: row.user_id,
                    user_type: row.user_type,
                  },
                },
              })
              .then(
                (res) => {
                  this.$notify({
                    title: this.$t("usersTables.removeUserSuccess"),
                    message: res.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-check-2",
                    type: "success",
                  });
                  swal.fire(
                    this.$t("usersTables.removeUserSuccess"),
                    "",
                    "success"
                  );
                  this.timeOutReload = setTimeout(() => {
                    this.getData();
                  }, 500);
                },
                (res) => {
                  this.$notify({
                    title: this.$t("usersTables.removeUserFailure"),
                    message: res.response.data.msg,
                    verticalAlign: "bottom",
                    icon: "nc-icon nc-alert-circle-i",
                    type: "danger",
                  });
                  swal.fire(
                    this.$t("usersTables.removeUserFailure"),
                    "",
                    "error"
                  );
                }
              );
          } else if (result.isDenied) {
            swal.fire(this.$t("usersTables.userNotRemoved"), "", "info");
          }
        });
    },
    handleDeleteAllUser() {
      swal
        .fire({
          title: this.$t("usersTables.confirmRemoveAll"),
          showDenyButton: true,
          confirmButtonText: this.$t("usersTables.yes"),
          denyButtonText: this.$t("usersTables.no"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.delete(`/face/users`).then(
              (res) => {
                this.$notify({
                  title: this.$t("usersTables.removeAllSuccess"),
                  message: res.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-check-2",
                  type: "success",
                });
                swal.fire(
                  this.$t("usersTables.removeAllSuccess"),
                  "",
                  "success"
                );
                this.timeOutReload = setTimeout(() => {
                  this.getData();
                }, 500);
              },
              (res) => {
                this.$notify({
                  title: this.$t("usersTables.removeAllFailure"),
                  message: res.response.data.msg,
                  verticalAlign: "bottom",
                  icon: "nc-icon nc-alert-circle-i",
                  type: "danger",
                });
                swal.fire(this.$t("usersTables.removeAllFailure"), "", "error");
              }
            );
          } else if (result.isDenied) {
            swal.fire(this.$t("usersTables.allUsersNotRemoved"), "", "info");
          }
        });
    },
  },
};
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
